import {useForm} from "react-hook-form";
import useActions from "../../../store/actions";
import {useNavigate} from "react-router-dom";


const useLogin = () => {
    // History
    let navigate;
    navigate = useNavigate();

    // Actions
    const { dispatch, useAuthActions } = useActions();
    const { actLogin } = useAuthActions();

    // Use Form
    const {control, handleSubmit} = useForm();

    // Handlers
    const handleLogin = (data) => {
        dispatch(actLogin(data.email, () => {
            navigate('/welcome')
        }));
    }
    return {
        control,
        handleSubmit,
        handleLogin
    }
}

export default useLogin;