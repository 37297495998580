import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useState} from "react";

const useWelcome = () => {
    // Selectors
    const { useAuthSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const auth = useSelector(authSelector);
    const [bandera, setBandera] = useState(false)

    const Animation = () => {
        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('amarillo')){
                    document.getElementById('amarillo').style.display = 'block';
                    document.getElementById('amarillo1').style.display = 'block';
                    document.getElementById('amarillo2').style.display = 'block';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('amarillo')){
                    document.getElementById('amarillo').style.display = 'none';
                    document.getElementById('amarillo1').style.display = 'none';
                    document.getElementById('amarillo2').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 3000);
        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('flecha')){
                    document.getElementById('flecha').style.display = 'block';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('flecha')){
                    document.getElementById('flecha').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 3000);

        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('hombre')){
                    document.getElementById('hombre').style.display = 'block';
                    document.getElementById('hombre1').style.display = 'none';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('hombre')){
                    document.getElementById('hombre').style.display = 'none';
                    document.getElementById('hombre1').style.display = 'block';
                    setBandera(true)
                }
            }
        }, 2500);

        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if(document.getElementById('mujer2')){
                    document.getElementById('mujer2').style.display = 'block';
                    document.getElementById('mujer1').style.display = 'none';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('mujer1')){
                    document.getElementById('mujer2').style.display = 'none';
                    document.getElementById('mujer1').style.display = 'block';
                    setBandera(true)
                }
            }
        }, 2500);
    }

    return {
        auth,
        Animation
    }
}

export default useWelcome;