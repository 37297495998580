import useTypes from "../../types";
import useServices from "../../services";
import axios from "axios";

const useResponsesActions = () => {
    const {
        SET_ANSWER,
        SET_HEART
    } = useTypes();

    const { useResponsesServices } = useServices()
    const { setLogService } = useResponsesServices();

    const actSetAnswer = (responses) => async dispatch => {
        try {
            await dispatch({
                type: SET_ANSWER,
                payload: responses
            })
        } catch (e) {
            console.log(e);
        }
    }

    const actSetHeart = (heart) => async dispatch => {
        try {

            await dispatch({
                type: SET_HEART,
                payload: heart
            })
        } catch (e) {
            console.log(e)
        }
    }

    const actSetInterval = (porcent) => async dispatch => {
        try {
            await dispatch({
                type: "PORCENT_DATA",
                payload: porcent
            })
        } catch (error) {
            console.log(error);
        }
    }

    const actGetResponses = (onSuccess) => async dispatch => {
        try {
            const res = axios.get('/exam/get-responses');
            const { data } = (await res).data;

            onSuccess && onSuccess(data)
        } catch (error) {
            console.log(error);
        }
    }

    const actSetLog = (number, onSuccess) => async dispatch => {
        try {
            await setLogService(number);

            onSuccess && onSuccess(number);
        } catch (error) {
            console.log(error);
        }
    }

    return {
        actSetAnswer,
        actSetHeart,
        actSetInterval,
        actGetResponses,
        actSetLog
    }
}

export default useResponsesActions;