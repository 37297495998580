import React from "react";
import usePreguntas from "./hook";

const Preguntas = () => {
    const {
        Animation,
        openQuestion,
        hearts,
    } = usePreguntas();
    Animation();

    return (
        <React.Fragment>
            <div className="fondo7">
                <img src="images/habitacion1.jpg" alt="habitacion1"/>
            </div>

            {/* <div className="luz">
                <img src="images/luz.png" alt="luz"/>
            </div> */}
            {/* <div className="luz1" id="luz1">
                <img src="images/luz1.png" alt="luz"/>
            </div> */}

            {/* <div className="palo">
                <img src="images/palo.png" alt="palo"/>
            </div> */}
            {/* <div className="sofa animate__animated animate__fadeIn">
                <img src="images/sofa.png" alt="sofa"/>
            </div> */}

            {/* <div className="rojo animate__animated animate__pulse animate__infinite">
                <img src="images/rojo.png" alt="rojo"/>
            </div> */}

            {/* <div className="puerta10 animate__animated animate__pulse animate__infinite" id="puerta10">
                <img src="images/puerta10.png" alt="puerta"/>
            </div> */}

            {/* Hearts */}
            {
                hearts.heart1 !== undefined ? (
                    <div className="cor">
                    </div>
                ) : (
                    <div className="cor" onClick={() => openQuestion('heart1')}><img src="images/corazon.png" alt="corazon" /></div>
                )
            }
            {
                hearts.heart2 !== undefined ? (
                    <div className="cor1">
                    </div>
                ) : (
                    <div className="cor1" onClick={() => openQuestion('heart2')}><img src="images/corazon.png"  alt="corazon" /></div>
                )
            }


        </React.Fragment>
    )
}

export default Preguntas;