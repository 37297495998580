import axios from "axios";

const useAuthProviders = () => {
    const login = (email) => {
        const data = {email: email, password: 'password'}
        return axios.post('/auth/login', data);
    }

    const register = (data) => {
        return axios.post('/auth/register', data);
    } 
  return {
      login,
      register
  }
}

export default useAuthProviders;