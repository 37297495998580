import { useState } from "react";

const useHome = () => {
    const [bandera, setBandera] = useState(false)
    const isSetTimeout = () => {
        setTimeout(() => {
            if (bandera) {
                if(document.getElementById('ventana')){
                    document.getElementById('ventana').style.display = 'block';
                    document.getElementById('ventana1').style.display = 'block';
                    document.getElementById('ventana2').style.display = 'block';
                    document.getElementById('ventana3').style.display = 'block';
                    // document.getElementById('ventana4').style.display = 'block';
                    // document.getElementById('ventana5').style.display = 'block';
                    // document.getElementById('ventana6').style.display = 'block';
                    // document.getElementById('ventana7').style.display = 'block';
                    setBandera(false)
                }

            } else {
                if(document.getElementById('ventana')){
                    document.getElementById('ventana').style.display = 'none';
                    document.getElementById('ventana1').style.display = 'none';
                    document.getElementById('ventana2').style.display = 'none';
                    document.getElementById('ventana3').style.display = 'none';
                    // document.getElementById('ventana4').style.display = 'none';
                    // document.getElementById('ventana5').style.display = 'none';
                    // document.getElementById('ventana6').style.display = 'none';
                    // document.getElementById('ventana7').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 3000);

        setTimeout(() => {
            if (bandera) {
                if(document.getElementById('corazon')){
                    document.getElementById('corazon').style.display = 'block';
                    document.getElementById('corazon1').style.display = 'block';
                    document.getElementById('corazon2').style.display = 'block';
                    document.getElementById('corazon3').style.display = 'block';
                    document.getElementById('corazon4').style.display = 'block';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('corazon')){
                    document.getElementById('corazon').style.display = 'none';
                    document.getElementById('corazon1').style.display = 'none';
                    document.getElementById('corazon2').style.display = 'none';
                    document.getElementById('corazon3').style.display = 'none';
                    document.getElementById('corazon4').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 4000);

        setTimeout(() => {
            if (bandera) {
                if(document.getElementById('cora')){
                    document.getElementById('cora').style.display = 'block';
                    setBandera(false)
                }


            } else {
                if(document.getElementById('cora')){
                    document.getElementById('cora').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 4000);

        setTimeout(() => {
            if (bandera) {
                if(document.getElementById('venta')){
                    document.getElementById('venta').style.display = 'block';
                    document.getElementById('venta1').style.display = 'block';
                    document.getElementById('venta2').style.display = 'block';
                    document.getElementById('venta3').style.display = 'block';
                    document.getElementById('venta4').style.display = 'block';
                    document.getElementById('venta5').style.display = 'block';
                    document.getElementById('venta6').style.display = 'block';
                    document.getElementById('venta7').style.display = 'block';

                    setBandera(false)
                }
            } else {
                if(document.getElementById('venta')){
                    document.getElementById('venta').style.display = 'none';
                    document.getElementById('venta1').style.display = 'none';
                    document.getElementById('venta2').style.display = 'none';
                    document.getElementById('venta3').style.display = 'none';
                    document.getElementById('venta4').style.display = 'none';
                    document.getElementById('venta5').style.display = 'none';
                    document.getElementById('venta6').style.display = 'none';
                    document.getElementById('venta7').style.display = 'none';
                    setBandera(true)
                }
            }
        }, 3000);
    }

    return {
        isSetTimeout
    }
}

export default useHome;