import useCreateSelector from "../createSelector";

const useResponsesSelectors = () => {
    const {createSelector} = useCreateSelector();

    const responsesSelector = createSelector(
        (state) => state.responses,
        (responses) => responses
    );

    const heartSelector = createSelector(
        (state) => state.heart,
        (heart) => heart
    )

    const porcentSelector = createSelector(
        (state) => state.roces,
        (roces) => roces.porcent
    )

    return {
        responsesSelector,
        heartSelector,
        porcentSelector
    }
}

export default useResponsesSelectors;