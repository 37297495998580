import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useState} from "react";

const useEscena2 = () => {
    // Selectors
    const { useAuthSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const auth = useSelector(authSelector);
    const [bandera, setBandera] = useState(false)

    const Hover = (type) => {
        if(type === 'over'){
            document.getElementById('cursor1').style.display = 'none';
            document.getElementById('cursor').style.display = 'block';
        }else {
            document.getElementById('cursor1').style.display = 'block';
            document.getElementById('cursor').style.display = 'none';
        }
    }
    const power = (type) => {
        if(type === 'over'){
            document.getElementById('cursor2').style.display = 'none';
            document.getElementById('cursor3').style.display = 'block';
        }else {
            document.getElementById('cursor2').style.display = 'block';
            document.getElementById('cursor3').style.display = 'none';
        }
    }
    const Animation = () => {
        setTimeout(() => {
            console.log(bandera)
            if (bandera) {
                if (document.getElementById('cuadroama')) {
                    document.getElementById('cuadroama').style.display = 'none';
                    setBandera(false)
                }


            } else {
                if (document.getElementById('cuadroama')) {
                    document.getElementById('cuadroama').style.display = 'block';
                    setBandera(true)
                }
            }
        }, 3000);

    }

    return {
        auth,
        Animation,
        Hover,
        power,
    }
}

export default useEscena2;