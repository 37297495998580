import React from 'react';
import useRegistro from "./hook";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import { Button } from "@mui/material";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import useLogin from "../login/hook";

const Registro = () => {
    const { auth, Animation, control, handleRegister, handleSubmit } = useRegistro();
    Animation()


    const InputComponent = (controlInput) => {
        const { field } = controlInput;
        console.log(field)
        return (

            <Input
                id="input-with-icon-adornment"
                placeholder={field.name === "text" ? "Nombre" : "example@boehringer-ingelheim.com"}
                type="email"
                name={field.name}
                value={field.value}
                ref={field.ref}
                onChange={(e) => {
                    field.onChange(e);
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <img src={field.name === "text" ? "images/user.png" : "images/arroba.png"} alt="user" />
                    </InputAdornment>
                }
            />
        )
    }

    return (
        <React.Fragment>
            <div className="registro">
                <img src="images/registro-100.jpg" alt="registro" />
            </div>

            {/* corazones */}
            <div className="corazon-amarillo" id="cora123" style={{ display: "none" }}>
                <img src="images/corazon-amarillo.png" alt="corazon-amarillo" />
            </div>

            {/* input */}
            <FormControl variant="standard" className="text-name">
                <Controller
                    control={control}
                    name="name"
                    render={InputComponent}
                />
            </FormControl>

            {/* input */}
            <FormControl variant="standard">
                <Controller
                    control={control}
                    name="email"
                    render={InputComponent}
                    icon="user.png"
                />
            </FormControl>

            {/* Boton */}
            <Button className="boton12" onClick={handleSubmit(handleRegister)}>
                <img src="images/boton-registro.png" alt="Button Login" />
            </Button>

        </React.Fragment>
    )
}

export default Registro;