import React from 'react';
import useFallo from './hook';
import {Link, useNavigate} from "react-router-dom"

const Fallo = () => {
    const {array} = useFallo();
    const navigate = useNavigate();

    const handleClick = () => {
      localStorage.clear();
      navigate('/login');
    }

    return (
        <React.Fragment>
            <div className="Fallo">
                <img src="/images/fallo-100.jpg" alt="Fallo"/>
            </div>
            <div className="jugar animate__animated animate__pulse animate__infinite" onClick={handleClick}>
                <img src="/images/jugar.png" alt="Fallo"/>
            </div>

            <Link to="/terms-and-conditions">
                <div className="cancelar animate__animated animate__pulse animate__infinite">
                    <img src="/images/cancelar.png" alt="Fallo"/>
                </div>
            </Link>
            {
                array.map((item, key) => (
                    <React.Fragment>
                        {item}
                    </React.Fragment>
                ))
            }


        </React.Fragment>
    )
}

export default Fallo;