import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import useActions from "../../../store/actions";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const useQuestion3 = () => {
    // Navigate
    const navigate = useNavigate();

    // Actions
    const { dispatch, useResponsesActions } = useActions();
    const { actSetAnswer } = useResponsesActions();

    // Selectors
    const {useAuthSelectors, useResponsesSelectors} = useSelectors();
    const {authSelector} = useAuthSelectors();
    const {responsesSelector} = useResponsesSelectors();
    const auth = useSelector(authSelector);
    const {user} = auth;
    const responses = useSelector(responsesSelector);

    // States
    const [selectRes, setSelectRes] = useState({ response: null });

    // useEffect(() => {
    //     if(responses['thirth'] !== undefined){
    //         navigate('/screen2')
    //     }

    //     // eslint-disable-next-line
    // }, [])

    // Handlers
    const selectedResponse = (response, question) => {
        const obj = {
            ...responses,
            thirth: response
        }
        setSelectRes({response: response});
        dispatch(actSetAnswer(obj));
        setTimeout(() => {
            navigate('/screen2');
        }, 3000)
    }

    return {
        user,
        selectedResponse,
        selectRes,
    }
}

export default useQuestion3;