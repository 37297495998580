import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useState} from "react";
import useActions from "../../../store/actions";
import {useNavigate} from "react-router-dom";

const usePreguntas = () => {
    // Navigate
    const navigate = useNavigate();

    // Actions
    const { dispatch, useResponsesActions } = useActions();
    const { actSetHeart } = useResponsesActions();

    // Selectors
    const { useAuthSelectors, useResponsesSelectors } = useSelectors();
    const { authSelector } = useAuthSelectors();
    const { responsesSelector, heartSelector } = useResponsesSelectors();
    const auth = useSelector(authSelector);
    const responses = useSelector(responsesSelector);
    const hearts = useSelector(heartSelector);
   
    // States
    const [bandera, setBandera] = useState(false);
    const [cor, setCor] = useState({});

    // Handlers
    const openQuestion = (heart) => {
        if(responses['first'] === undefined){
            cor[heart] = 1;
            setCor(cor);
            dispatch(actSetHeart(cor))
            navigate('/question-1');
        }else{
            cor[heart] = 2;
            setCor(cor);
            dispatch(actSetHeart(cor))
            navigate('/question-2');
        }
    }

    const Animation = () => {
        setTimeout(() => {
            if (bandera) {
                if(document.getElementById('luz1')){
                    document.getElementById('luz1').style.display = 'none';
                    setBandera(false)
                }
            } else {
                if(document.getElementById('luz1')){
                    document.getElementById('luz1').style.display = 'block';
                    setBandera(true)
                }
            }
        }, 2000);
    }
    return {
        auth,
        Animation,
        openQuestion,
        hearts
    }
}

export default usePreguntas;