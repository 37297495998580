import React from "react";
import useQuestion3 from "./hook";

const FourQuestion = () => {
    const {
        user,
        selectedResponse,
        selectRes
    } = useQuestion3();

    return (
        <React.Fragment>
            {
                user.gender === "Female" ? (
                    // eslint-disable-next-line
                    <img
                        src="/images/pregunti2.jpg"
                        alt="Background Image Question 1"
                        className="background-question-1"
                    />
                ) : (
                    // eslint-disable-next-line
                    <img
                        src="/images/thirt2.jpg"
                        alt="Background Image Question 1"
                        className="background-question-1"
                    />
                )
            }
            {
                user.gender === "Female" ? (
                    <React.Fragment>
                        <div className="true-response-4-women" onClick={() => selectedResponse(true, 'first')}>
                            {
                                selectRes.response && (
                                    <img src="/images/true-hover.png" alt="True Response Hover" />
                                )
                            }
                        </div>
                        <div className="false-response-4-women" onClick={() => selectedResponse(false, 'first')}>
                            {
                                selectRes.response === false && (
                                    <img src="/images/false-hover.png" alt="False Response Hover" />
                                )
                            }
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="true-response-4" onClick={() => selectedResponse(true, 'first')}>
                            {
                                selectRes.response && (
                                    <img src="/images/true-hover.png" alt="True Response Hover" />
                                )
                            }
                        </div>
                        <div className="false-response-4" onClick={() => selectedResponse(false, 'first')}>
                            {
                                selectRes.response === false && (
                                    <img src="/images/false-hover.png" alt="False Response Hover" />
                                )
                            }
                        </div>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    );
}

export default FourQuestion;