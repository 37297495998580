import React from "react";
import useEscena2 from "./hook";
import {Link} from "react-router-dom";
import Preguntas from "../Preguntas";

const Escena2 = () => {
    const {auth, Animation, Hover, power} = useEscena2();
    Animation()
return (
    <React.Fragment>
        <Link to="/preguntas">
        <div className="fondo6">
            <img src="images/fondo6.jpg" alt="fondo6"/>
        </div>
        </Link>
        {/*decoration*/}
        <div className="cursor" id="cursor">
            {/* <img src="images/cursor.png" alt="cursor" onMouseLeave={() => Hover('leave')}/> */}
        </div>
        <div className="cursor1" id="cursor1" style={{ display:"none" }} onMouseOver={() => Hover('over')}>
            {/* <img src="images/cursor1.png" alt="cursor1"/> */}
        </div>

        <div className="cuadro1" id="cursor3"onMouseLeave={() => power('leave')} >
            {/* <img src="images/cuadro1.png" alt="cursor"/> */}
        </div>
        <div className="cuadro" id="cursor2" style={{ display:"none" }} onMouseOver={() => power('over')}>
            <img src="images/cuadro.png" alt="cursor"/>
        </div>

        <Link to="/preguntas">
        <div className="cuadroama" id="cuadroama" style={{ display:"block" }}>
            <img src="images/puertascree2.png" alt="cuadroama"/>
        </div>
        </Link>

            <div className="puerta" id="puerta" style={{ display:"none" }}>
                <img src="images/puerta.png" alt="puerta"/>
            </div>
            <div className="puerta1" id="puerta1" style={{ display:"none" }}>
                <img src="images/puerta.png" alt="puerta1"/>
            </div>
    </React.Fragment>
)
}

export default Escena2;