import React, { useEffect } from "react";
import useHome from "./hook";
import "animate.css";
import {Link} from "react-router-dom";


const Home = () => {
    const {isSetTimeout} = useHome();

    isSetTimeout();
    
    return (
        <React.Fragment>

            <div className="fondo">
                <img src="images/fondo1.jpg" alt="fondo"/>
            </div>
            <Link to="/Login">
                <div className="play animate__animated animate__pulse animate__infinite	">
                    <img src="images/play.png" alt="fondo"/>
                </div>
            </Link>

            <div className="ventana" id="ventana">
                <img src="images/casa.png" alt="ventana"/>
            </div>
            <div className="ventana2" id="ventana1">
                <img src="images/casa1.png" alt="ventana"/>
            </div>
            <div className="ventana3" id="ventana2">
                <img src="images/lampara1.png" alt="ventana"/>
            </div>
            <div className="ventana4" id="ventana3">
                <img src="images/casa2.png" alt="ventana"/>
            </div>

            
        </React.Fragment>
    )
}

export default Home;