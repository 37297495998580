import React from 'react';
import useLoader from './hook';

const Loader = () => {
  const {
    width
  } = useLoader();

  return (
      <React.Fragment>
        <div className="loader">
          <img src="/images/loaders.jpg" alt="loader"/>
        </div>
        <div className="loader-input" id="loader" style={{ width: `${width}%` }}></div>
      </React.Fragment>
  )
}

export default Loader;