import {useDispatch} from "react-redux";
import useAuthActions from "./auth";
import useResponsesActions from "./responses";

const useActions = () => {
  const dispatch = useDispatch();

  return {
    dispatch,
    useAuthActions,
    useResponsesActions,
  }
}

export default useActions;