import useSelectors from "../../../store/selectors";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useActions from "../../../store/actions";

const useQuestion2 = () => {
    // Navigate
    const navigate = useNavigate();

    // Actions
    const { dispatch, useResponsesActions } = useActions();
    const { actSetAnswer } = useResponsesActions();

    // Selectors
    const {useAuthSelectors, useResponsesSelectors} = useSelectors();
    const {authSelector} = useAuthSelectors();
    const {responsesSelector} = useResponsesSelectors();
    const auth = useSelector(authSelector);
    const {user} = auth;
    const responses = useSelector(responsesSelector);

    // States
    const [selectRes, setSelectRes] = useState({ response: null });

    useEffect(() => {
        if(responses['second'] !== undefined){
            navigate('/preguntas')
        }

        // eslint-disable-next-line
    }, [])

    // Handlers
    const selectedResponse = (response) => {
        const obj = {
            ...responses,
            second: response
        }
        setSelectRes({response: response});
        dispatch(actSetAnswer(obj));
        setTimeout(() => {
            navigate('/habitacion2');
        }, 3000);
    }

    return {
        user,
        selectedResponse,
        selectRes,
    }
}


export default useQuestion2;