import useServices from "../../services";
import useTypes from "../../types";

const useAuthActions = () => {
    const {useAuthServices} = useServices();
    const {loginService, registerService} = useAuthServices();

    const { LOGIN } = useTypes();

    const actLogin = (email, onSuccess) => async dispatch => {
        try {
            const res = await loginService(email);
            const {data} = res.data;

            onSuccess && onSuccess()

            dispatch({
                type: LOGIN,
                payload: data
            })
        } catch (error) {
            console.log(error)
        }
    }

    const actRegister = (request) => async dispatch => {
        const { onSuccess, onError, data } = request;
        try {
            await registerService(data);
            onSuccess && onSuccess();
        } catch (error) {
            onError && onError(error);
        }
    }

    return {
        actLogin,
        actRegister
    }
}

export default useAuthActions;