import React from "react";
import { useNavigate } from "react-router";

const Modal = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    return (
        <React.Fragment>
            <div className="modal">
                <img src="/images/modal.png" alt="Modal" onClick={handleClick}/>
            </div>
        </React.Fragment>
    );
}

export default Modal;