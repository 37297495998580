import createReducer from "../createReducer";
import useTypes from "../../types";

const useAuthReducers = () => {
    const {LOGIN} = useTypes();

    const auth = createReducer({}, {
        [LOGIN](state, action) {
            return {
                ...state,
                ...action.payload
            }
        }
    });

    return {
        auth
    }
}

export default useAuthReducers;