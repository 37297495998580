import useCreateSelector from "../createSelector";

const useAuthSelectors = () => {
    const {createSelector} = useCreateSelector();

    const authSelector = createSelector(
        (state) => state.auth,
        (auth) => auth
    );

    return {
        authSelector
    }
}

export default useAuthSelectors;