import useAuthSelectors from "./auth";
import useResponsesSelectors from "./responses";

const useSelectors = () => {
  return {
    useAuthSelectors,
    useResponsesSelectors
  }
}

export default useSelectors;